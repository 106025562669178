.bilder-list li {
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: bold;
  line-height: 1.44;
  color: #004aa1;
  display: inline-block;
  vertical-align: top;
}

.bilder-add {
  border: 2px dashed #4D4C4D;
  display: inline-block;
  width: 200px;
  height: 137px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.bilder-add:hover {
  border: 2px solid #4D4C4D;
}

.bilder-add img {
  width: 80px;
  /* align-self: center; */
}