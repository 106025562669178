.breadcrumb {
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    border-radius: 0;
}
.breadcrumb a {
    color: #4d4d4d;
    text-transform: uppercase;
}
.breadcrumb a:last-child {
    border-bottom: 2px solid #ffa500;
    text-decoration: none;
}
.breadcrumb .path-separator {
    display: inline-block;
    margin: 2px 10px;
    font-weight: bold;
}