.page-section {
  border: none;
  border-bottom: solid 1px #f1f1f1;
  background-color: transparent;
  box-shadow: none;
  border-radius: 0px;
  padding-bottom: 10px;
}

.page-section > .panel-heading {
  background-color: transparent;
  border: none;
  padding-left: 0;
  padding-right: 0;
  color: #4a4a4a;
}

.page-section-header a {
  cursor: pointer;
}

.page-section-header h1 {
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 9px;
  font-size: 19px;
  font-weight: bold;
  line-height: 1.11;
}

.page-section-header button {
  text-align: left;
  padding: 8px 15px 8px 15px;
  min-width: 210px;
}

.page-section-header button > span.glyphicon {
  margin-right: 12px;
}

.page-section .collapse-icon {
  display: inline-block;
  margin-right: 16px;
  margin-top: -6px;
  transition: transform 0.3s;
}

.page-section a[aria-expanded="true"] .collapse-icon {
  transform: rotate(90deg);
}

.page-section > div > div.panel-body {
  padding: 0 32px;
}
