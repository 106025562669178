.common-checkbox > label {
  padding-left: 0px;
}
.common-checkbox.disabled > label {
  text-decoration: line-through;
}
.common-checkbox img {
  vertical-align: middle;
  width: 20px;
  margin-right: 5px;
  margin-bottom: 4px;
}
.common-checkbox input[type="checkbox"] {
  margin: 0;
  position: absolute;
  visibility: hidden;
}
