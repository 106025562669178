.filter-menu {
  z-index: 10000;
}
.filter .filter-menu-list {
  padding: 0px;
  list-style: none;
}

.filter .filter-menu-list > li {
  font-size: 16px;
  letter-spacing: 0.3;
  color: #4a4a4a;
  white-space: nowrap;
}

.filter .filter-menu {
  padding: 11px 10px 21px 10px;
  margin: 0px;
  border: solid 1px #dedede;
  border-radius: 0px;
  min-width: 300px;
}
.filter .filter-menu .clear-filter {
  font-size: 1.2rem;
  margin: 8px 0;
}
.filter .filter-menu .clear-filter a {
  cursor: pointer;
}
.filter .filter-menu-input {
  font-size: 16px;
  height: 40px;
  padding: 9px 12px 9px 12px;
  border-radius: 7px 7px 7px 7px;
  border-color: #e5e5e5;
  border-right: none;
  box-shadow: none;
}

.filter .filter-menu .input-group-addon {
  background-color: #ffffff;
  border-radius: 7px 7px 7px 7px;
  border-left: none;
  border-color: #e5e5e5;
}

.filter .filter-menu .input-group-btn button {
  background-color: #ffffff;
  border-radius: 7px 7px 7px 7px;
  border-top: solid 1px #e5e5e5;
  border-right: solid 1px #e5e5e5;
  border-bottom: solid 1px #e5e5e5;
  border-left: none;
  padding: 8px 12px 7px 12px;
}

input.filter-menu-input:not([value=""]) ~ .fm-addon-search {
  display: none;
}

input.filter-menu-input[value=""] ~ .fm-addon-clear {
  display: none;
}

.filter .filter-menu .fm-close {
  max-width: 220px;
  margin: 15px auto 0px auto;
}
