.byvaap-cmyk {
  width: 38px;
  height: 46px;
  object-fit: contain;
}

.navbar-item-small {
  min-width: 130px;
}

/* ========== Bootstrap overrides ========== */
.navbar-bym {
  background-color: #d8d8d8;
  min-height: 65px;
  margin-bottom: 0;
}
.navbar-bym .navbar-brand {
  color: #4d4d4d;
  height: 64px;
  font-size: 22px;
  font-weight: 900;
  line-height: inherit;
  padding-top: 17px;
  padding-bottom: 13px;
}

/* Responsiv navigasjonstop */
@media (max-width: 866px) {
  .navbar-bym .navbar-brand {   
    font-size: 16px;
    font-weight: 600;
    padding-top:18px;
  }
}
@media (max-width: 335px) {
  .navbar-bym .navbar-brand {   
    min-width: 335px;
  }
}
.navbar-bym .navbar-brand > img {
  display: inline-block;
  margin-right: 11px;
  margin-top: -14px;
}
.navbar-bym .navbar-brand:hover,
.navbar-bym .navbar-brand:focus {
  color: #5e5e5e;
  background-color: transparent;
}
.navbar-bym .navbar-text {
  color: #4d4d4d;
}
.navbar-bym .navbar-nav > li > a {
  text-transform: uppercase;
  color: #4d4d4d;
  font-size: 16px;
}
.navbar-bym .navbar-nav > li > a:hover,
.navbar-bym .navbar-nav > li > a:focus {
  color: #4d4d4d;
  background-color: transparent;
}
.navbar-bym .navbar-nav > .active > a,
.navbar-bym .navbar-nav > .active > a:hover,
.navbar-bym .navbar-nav > .active > a:focus {
  color: #4d4d4d;
  background-color: transparent;
}
.navbar-bym .navbar-nav > li:nth-child(1) {
  min-width: 140px;
}
.navbar-bym .navbar-nav > li:nth-child(2) {
  min-width: 140px;
}
.navbar-bym .navbar-nav > li:nth-child(3) {
  min-width: 140px;
}
.navbar-bym .navbar-nav > li:nth-child(4) {
  min-width: 126px;
}



.navbar-bym .navbar-nav > .disabled > a,
.navbar-bym .navbar-nav > .disabled > a:hover,
.navbar-bym .navbar-nav > .disabled > a:focus {
  color: #ccc;
  background-color: transparent;
}
.navbar-bym .navbar-toggle {
  border-color: #ddd;
}
.navbar-bym .navbar-toggle:hover,
.navbar-bym .navbar-toggle:focus {
  background-color: #ddd;
}
.navbar-bym .navbar-toggle .icon-bar {
  background-color: #888;
}
.navbar-bym .navbar-collapse,
.navbar-bym .navbar-form {
  border-color: #9b9b9b;
}
.navbar-bym .navbar-nav > .open > a,
.navbar-bym .navbar-nav > .open > a:hover,
.navbar-bym .navbar-nav > .open > a:focus {
  color: #555;
  background-color: #9b9b9b;
}
.navbar-bym .navbar-nav > .open > .dropdown-menu > li > a {
  font-size: 16px;
}
.navbar-bym .navbar-nav > li.active > a,
.navbar-bym .navbar-nav .open .dropdown-menu > li.active > a {
  font-weight: bold;
}
@media (min-width: 768px) {
  .navbar-bym {
    border-bottom: solid 1px #4d4c4d;
  }
  .navbar-bym .navbar-nav > li > a {
    padding-top: 23px;
    padding-bottom: 22px;
    padding-left: 15px;
    padding-right: 12px;
    border-left: solid 1px #4d4c4d;
  }
  .navbar-bym .navbar-nav > .open > .dropdown-menu {
    min-width: 250px;
    padding-top: 23px;
    padding-bottom: 9px;
    border-radius: 5px;
  }
  .navbar-bym .navbar-nav > .open > .dropdown-menu > li > a {
    padding: 8px 20px;
    color: #4d4d4d;
    background-color: transparent;
  }
}
@media (max-width: 767px) {
  .navbar-bym .navbar-header {
    border-bottom: solid 3px #00548c;
  }
  .navbar-bym .navbar-nav {
    margin: 0px -15px;
  }
  .navbar-bym .navbar-nav .open .dropdown-menu > li > a {
    color: #4d4d4d;
  }
  .navbar-bym .navbar-nav .open .dropdown-menu > li > a:hover,
  .navbar-bym .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #4d4d4d;
    background-color: transparent;
  }
  .navbar-bym .navbar-nav .open .dropdown-menu > .active > a,
  .navbar-bym .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar-bym .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #4d4d4d;
    background-color: transparent;
  }
  .navbar-bym .navbar-nav .open .dropdown-menu > .disabled > a,
  .navbar-bym .navbar-nav .open .dropdown-menu > .disabled > a:hover,
  .navbar-bym .navbar-nav .open .dropdown-menu > .disabled > a:focus {
    color: #ccc;
    background-color: transparent;
  }
}
.navbar-bym .navbar-link {
  color: #4d4d4d;
}
.navbar-bym .navbar-link:hover {
  color: #333;
}
.navbar-bym .btn-link {
  color: #4d4d4d;
}
.navbar-bym .btn-link:hover,
.navbar-bym .btn-link:focus {
  color: #333;
}
.navbar-bym .btn-link[disabled]:hover,
fieldset[disabled] .navbar-bym .btn-link:hover,
.navbar-bym .btn-link[disabled]:focus,
fieldset[disabled] .navbar-bym .btn-link:focus {
  color: #ccc;
}
