.fakturageneration-viewer {
  /* border: 1px solid red; */

}

.fakturageneration-viewer div.month-box {
  display: inline-block;
  padding: 10px 15px 10px 15px; 
  border: 1px solid transparent;
  pointer-events: none;
}
.fakturageneration-viewer div.month-box .checkbox {
  opacity: 0.6;
}
.fakturageneration-viewer div.month-box:first-child {
  padding-left:0;
}
.fakturageneration-viewer div.month-box.seasonStart {
  border-left: 1px dashed gray;
}
.fakturageneration-viewer div.month-box.seasonEnd {
  border-right: 1px dashed gray;
}

.fakturageneration-viewer div.form-group {
  display: inline-block;
  /* min-width: 40px; */
  position: relative;
  padding-bottom: 10px;
  padding: 0 0 10px 0;
}
.fakturageneration-viewer div.form-group:first-child {
  /* padding: 0 15px 10px 0; */
}
.fakturageneration-viewer div.form-group .checkbox label {
  min-width: 28px;
  text-align: center;
}
.fakturageneration-viewer div.form-group .checkbox label > img {
  margin-right: 0;
}
.fakturageneration-viewer div.form-group > label {
  position: absolute;
  bottom: 0;
  min-width: 28px;
  text-align: center;
  font-weight: normal;
}