.forside {
    display: flex;
    justify-content: center;
}
.forside-avdelinger div.radio {
    display: inline-block;
    margin-right: 12px;
}
.forside > div {
    flex-basis: 75%;
    max-width: 900px;
}
.forside .page-header  {
    font-size: 40px;
    text-align: center;
    padding: 0;
}
.simple-table {
    width: 100%;
}
.simple-table th {
    background-color: #f3f3f3;
    border-right: 1px solid #d7d7d7;
    font-size: 16px;
    padding: 8px 20px;
}

.simple-table td {
    border-right: 1px solid #d7d7d7;
    border-bottom: 1px solid #d7d7d7;
    padding: 6px 20px;
}
.simple-table td:last-child, .simple-table th:last-child {
    border-right: 0;
}

.forside .autocomplete.freetext-filter {
    margin-top: 30px;
    width: 100%;
}
.forside .autocomplete.freetext-filter input {
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: #f1f1f1;
    border-color: #ebebeb;
    box-shadow: none;
}

.forside .autocomplete .rbt-input-hint-container {
    margin-right: 54px;
}

.forside .autocomplete .rbt-input-hint-container > div {
    margin-top: 10px!important;
}

.forside .autocomplete .freetext-searchicon {
    top: 0;
    display: inline-block;
    padding: 11px 15px 12px 15px;
    border: 1px solid #ebebeb;
    background-color: #f1f1f1;
    min-height: 46px;
}
.forside .autocomplete input::placeholder {
    text-align: center;
 }
 .forside .forside-icons {
     display: flex;
     justify-content: space-between;
     align-content: flex-end;
     margin-top: 30px;
     align-items: stretch;
 }

 .forside .forside-icons > a {
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding-bottom: 20px;
    flex-grow: 1;
    display: flex;
    height: 200px;
    margin-right: 20px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}
.forside .forside-icons > a:hover {
    border: 1px solid #cccccc;
    text-decoration: none;
}
.forside .forside-icons > a h4 {
    color: rgb(51, 51, 51);;
}

.forside .forside-icons > a:last-child {
    margin-right: 0;
}
