.bym-table {
  margin-bottom: 4px;
}
.bym-table > thead > tr > th {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  padding: 15px 18px 16px 18px;
  /* border-bottom: solid 0.5px rgba(151, 151, 151, 0.57); /* TODO Will not blend */
  border: none;
}

.bym-table > tbody > tr > td {
  font-size: 16px;
  font-weight: normal;
  color: #000000;
  padding: 12px 18px 13px 18px;
  /* border-bottom: solid 0.5px rgba(151, 151, 151, 0.57); /* TODO Will not blend */
}

.bym-table > tbody > tr:hover {
  opacity: 0.87;
  background-color: #f5f5f5;
}

.bym-table.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #ffffff;
}

.bym-table.table-striped > tbody > tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.bym-table.table-striped > thead > tr {
  background-color: #f3f3f3;
  border: solid 1px #d8d8d8;
}
