.uge-page {
  margin: 0 45px;
  padding: 0 35px;
  background-color: #fff;
  min-height: 600px;
}
.page-header {
  border-bottom: solid 1px #cccccc;
  padding-bottom: 0;
  margin-top: 20px;
  margin-bottom: 0;
}

.page-header > h1 {
  padding-top: 15px;
  padding-bottom: 0;
  margin-bottom: 8px;
  font-size: 26px;
  font-weight: bold;
  color: #4d4d4d;
}

.page-body {
  padding-bottom: 60px;
}
