.f-filter-count {
  min-width: 21px;
  min-height: 20px;
  /* height: 20px; */
  border-radius: 10px;
  padding: 2px;
  margin-left: 12px;
  margin-right: 12px;
  display: inline-block;
  background: #d9eaf5;
  border: solid 1px #979797;
  font-size: 11px;
  letter-spacing: 0.2px;
  text-align: center;
  color: #4a4a4a;
}
