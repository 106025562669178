.expandable-list .panel {
  border: 0;
  margin: 0!important;
  border-radius: 0!important;
}
.expandable-list .panel-heading {
  border-radius: 0!important;
  position: relative;
  display: flex;
  /* justify-content: space-between; */
}
.expandable-list .panel-heading > a{
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.expandable-list .panel-heading button {
  font-size: 14px;
  /* align-self: flex-end; */
  /* margin-bottom: 4px; */
}

.expandable-list .panel-heading > a {
  color: #777;
  font-weight: bold;
  cursor: pointer;
}
.expandable-list .panel-heading a:hover {
  text-decoration: none;
}
.expandable-list .panel-body {
  border: 0!important;
}

.expandable-list .panel:nth-child(even) .panel-heading {
  background-color: transparent;
}


.expandable-list img.collapse-icon {
  display: inline-block;
  margin-right: 16px;
  margin-top: -6px;
  transition: transform 0.3s;
}

.expandable-list a[aria-expanded="true"] .collapse-icon {
  transform: rotate(90deg);
}
