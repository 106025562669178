.pagination-control {
  font-size: 12px;
  font-weight: normal;
  color: #000000;
  opacity: 0.64;
  margin-bottom: 4px;
}

.pagination-control .pc-page-number {
  text-align: right;
  display: inline-block;
  min-width: 60px;
}

.pagination-control .pc-space-small {
  margin-left: 18px;
}

.pagination-control .pc-space-large {
  margin-left: 30px;
}

.pagination-control select {
  border: none;
}

.pagination-control button {
  background: transparent;
  border: none;
}
