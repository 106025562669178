#editKundenummer .radio-inline {
    margin-right: 40px;
}

#editKundenummer table.kundeInfo {
    width: 65%;
}

#editKundenummer table.kundeInfo td:first-child {
    font-weight: bold;
    width: 35%;
}
