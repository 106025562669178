#AdminPage ul.nav li:first-child {
  margin-left: 0px;
}
#AdminPage ul.nav li a {
  border-radius: 0;
  border-top: 0;
  border-bottom: 1px solid #ddd;
}
#AdminPage .tab-content {
  padding-top: 10px;
}
