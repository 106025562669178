.bym-spinner-button {
  position: relative;
  display: flex;
  padding-right: 25px;
  align-items: center;
}
.bym-spinner-button svg {
  height: 18px;
  width: 18px;
  position: absolute;
  right: 4px;
}