// @import "styles";

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeAway {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;

  }
}

.leaflet-container::after {
  font-size: 18px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 461;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events:none;
}

.double-tap-message::after {
  content: "Bruk to fingre for å navigere i kartet og zoome";
  animation: fadeIn 1500ms forwards;
}
