.bilde-dialog {
    display: flex;
    align-self: center;
    max-width: 90%;
    max-height: 90%;
    justify-content: center;
    width: auto;
  }
  .bilde-dialog .modal-content {
    max-width: 100%;
    max-height: 100%;
  }
  .bilde-dialog .modal-body {
    max-width: 100%;
    max-height: 80%;
    display: flex;
    justify-content: center;
    padding: 0;
  }
  .bilde-dialog .modal-body img {
    max-width: 100%;
    max-height: 90%;
    object-fit: contain;
  }
  