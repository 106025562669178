.details-view {
  border-collapse: collapse;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between;
  margin-bottom: 40px; */
  padding: 10px 20px;
  margin: -1px -20px 20px -20px;
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
  background-color: rgba(241, 247, 251, 0.5);
}
.details-column {
  padding-left: 70px;
  min-width: 300px;
}
.details-column:first-child {
  padding-left: 0;
}
.details-column:last-child {
  padding-right: 0;
}

.details-border {
  border-left:1px solid #d3d3d3;
  border-right:1px solid #d3d3d3;
}
table.details-table td:first-child {
  padding-left: 0px;
}

.table.details-table tr td{
  border-top: 0;
  border-bottom: 1px solid #e0e0e0;
}
.table.details-table tr:last-child td{
  border-bottom: 0;
}

.table.custom-details tr td{
  border-left: 1px solid #e0e0e0;
}

.details-view label {
  margin: 0;
  font-weight: normal;
}
.details-view .field-name {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 12px;
  line-height: 1.92;
  color: #4d4d4d;
}

.center{
  display: table;
  margin: auto;
}

.details-view .field-value {
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.92;
  color: #4d4d4d;
}

.details-value {
  text-align: left;
}