.bymmap-editable {
  position: relative;
  padding: 0;
  height: 60vh;
}
.bymmap-editable .leaflet-draw {
  display: none;
}
.bymmap-editable .leaflet-container {
  height: 60vh;
}

// draw/delete & help buttons
.bymmap-edit-buttons {
  position: absolute;
  padding: 0 0 10px 10px;
  bottom: 20px;
  z-index: 1002;
  display: flex;
  flex-direction: column;

  button {
    box-shadow: 3px 4px 4px -4px rgba(0,0,0,0.5);
    border: 0;
    padding-top: 11px;
    padding-bottom: 8px;
    background-color: #2a2859;
    &:hover {
      background-color: #1f42aa;
    }
    &:disabled {
      background-color: #f2f2f2!important;
      opacity: 1;
      border: 0px;
      svg {
        path[stroke="white"] {
          stroke: #666666;
        }
        path[fill="white"] {
          fill: #666666;
        }
      }
    }
  }

  button + button {
    margin-top: 10px;
  }

  .bym-button-help {
    margin-top: 70px;
  }
}

// Container - informasjonsboks til bruker
.bymmap-explanation {
  box-shadow: 3px 4px 4px -4px rgba(0,0,0,0.5);
  background-color: #2a2859;
  color: #fff;
  opacity: 0.9;
  position: absolute;
  font-size: 1.2em;
  top: 100px;
  left: 10px;
  z-index: 1010;
  padding: 15px;
  margin-right: 10px;

  button {
    background-color: inherit;
    color: #fff;
    border: 0px;
    &:focus
    &:hover {
      border: 0px;
    }
  }
}

// Innhold - informasjonsboks til bruker
.bymmap-explanation-dialog {
  &-header {
    display: flex;
    justify-content: space-between;

    span {
      display: flex;
      align-items: center;
    }
  }

  .glyphicon-remove { cursor: pointer; margin-top: -15px;}
  .glyphicon-remove:hover { cursor: pointer;}

  &-body {
    margin-top: 20px;
  }
}

.leaflet-bar {
  border: 0px !important;
}
