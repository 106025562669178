.toast-visible {
  margin-top: 0;
  transition: margin-top ease-out 1s;
}

.toast-hidden {
  margin-top: -200px;
  transition: margin-top ease-in 1s;
}

.toast {
  position: static;
  width: 100%;
  /* max-height: 200px; */
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top: 0;
}
.toast h4 {
  margin-top: 0;
}

.toast-top-fixed {
  position: fixed;
  top: 67px;
  left: 50%;
  margin-left: -350px;
  width: 700px;
  z-index: 10000000;
  overflow: hidden;
}
.toast-top-fixed .content {
  display: flex;
  justify-content: center;
  overflow-wrap: anywhere;
  word-break: break-all;
  /* overflow-y: scroll; */
}


@media (max-width: 767px) {

  .toast-top-fixed {
    position: fixed;
    top: 67px;
    left: 0;
    margin-left:0;
    width: 100%;
  }

}
