div.fakturastatus-betalt {
  color: #22b473;
}
div.fakturastatus-forfalt {
  color: #ff9f97;
}
td.faktura-actions {
  text-align: right;
}
td.faktura-actions a {
  margin-left: 15px;
}
td.faktura-actions a:first-child {
  margin-left: 0;
}

table#faktura-liste tbody {
  border-top: 1px;
}
table#faktura-liste tbody tr {
  background-color: inherit;
}
table#faktura-liste tbody tr:nth-child(2) td {
  background-color: inherit;
  border-top: 0px solid;
}

table#faktura-liste tbody:nth-child(odd) {
  background-color: #f3f3f3;
}
#faktura-liste .collapse-icon {
  cursor: pointer;
  transition: transform 0.3s;
}
#faktura-liste .collapse-icon.expanded {
  transform: rotate(90deg);
}
#faktura-liste .kreditnota-icon {
  /* https://stackoverflow.com/questions/22252472/how-to-change-the-color-of-an-svg-element/53336754#53336754 */
  filter: brightness(0) saturate(100%) invert(71%) sepia(33%) saturate(4974%) hue-rotate(314deg) brightness(131%) contrast(112%);
}
#faktura-liste .simple-table thead th {
  background-color: #f6e82f;
}
#faktura-liste tr.details .panel {
  /* text-replace: ; */
  /* border: 1px solid red; */
  /* transition: opacity 0.3s ease-out; */
  animation: scaleIn 0.3s;
}
@keyframes scaleIn {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
