.add-eiendom-dialog {
  width: 80%;
  max-width: 1140px;
  min-width: 600px;
}

.add-eiendom-dialog .control-label {
  width: 100%;
}

/* .add-eiendom-dialog .modal-body {
  padding: 15px 0 0 0;
}
.add-eiendom-dialog .row {
  margin-bottom: 10px;
}
.add-neweiendom-button {
  margin-top: 22px;
}
.tidsluke-column .bym-checkboxgroup {
  margin-top: -2px;
}
.tidsluke-column span {
  display: inline-block;
  margin-top: 8px;
}
.add-neweiendom-row {
  background-color: #f3f3f3;
  background-color: #f1f7fb;
  margin-left: -15px !important;
  padding: 10px 0;
  margin-top: 20px;
}
.add-neweiendom-row > div {
  padding-right: 0;
}
.neweiendomer-row {
  margin-top: 20px;
  margin-bottom: 20px !important;
  padding-left: 15px;
  padding-right: 15px;
}
.neweiendom-error {
  color: #a94442;
  text-align: center;
  padding-top: 10px;
}
// global error
.add-eiendom-dialog .bym-form > .has-error {
  text-align: center;
  font-size: 1.1em;
} */

.add-eiendom-button,
.add-eiendom-button:hover,
.add-eiendom-button:link {
  margin-top: 15px;
  padding: 8px 15px;
  min-width: 210px;
}
.add-eiendom-button .glyphicon {
  margin-right: 16px;
}

.saksnummer-verifisert {
  background-color: #dff0d8;
}

.saksnummer-feil {
  background-color: #f0d4d8;
}

.valider-button {
  font-size: small;
}

.sentrer-kart-button {
  padding-top: 19px;
}
