.eiendommer-tabs-panel {
  margin: 0;
}

.eiendommer-tabs-panel .eiendommer-tabs {
  width: 270px;
}

.eiendommer-tabs-panel label {
  border-color: #e9e9e9 !important;
  padding: 4px 60px 6px 15px;
  float: left;
  width: auto;
  background: #fff;
}

.eiendommer-tabs-panel label.active, .eiendommer-tabs-panel label.active:hover {
  background: #f0f0f0;
}

.eiendommer-tabs-panel button {
  padding: 8px 22px 8px 22px!important;
}

.eiendommer-tabs-panel button img,
.eiendommer-tabs-panel label img {
  margin-right: 10px;
  max-height: 18px;
}
