.spinner {
  background: url("../../../images/spinner.svg");
  background-repeat: no-repeat;
  background-position: center;
  /* background-size: 10rem; */
  width: 5rem;
  height: 5rem;
  margin: auto; 
}
.page-spinner {
  height: 40rem;
  width: 20rem;
  display: flex;
  justify-content: center;
}
.page-spinner p {
  padding-top: 24rem;
}
.inline-spinner {
  height: 1em;
  width: 1em;
  background-size: 1em;
  display: inline-block;
}