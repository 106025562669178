.uge-fakturatotal {
  padding: 20px;
}
.uge-fakturatotal-sum-align {
  text-align: right;
}
.totalsum-info {
  padding-bottom: 1em;
}

@media (max-width: 1600px) {
  .opprinneligFakturaColumn,
  .fakturaDatoColumn {
    display: none;
  }
}
@media (max-width: 1200px) {
  .restbelopColumn {
    display: none;
  }
}
