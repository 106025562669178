.left-align-input .rdtPicker {
  left: 0 !important;
}

.right-align-input .rdtPicker {
  right: 0 !important;
}

.top-align-input .rdtPicker {
  bottom: 36px;
}