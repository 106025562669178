.header-cell {
  cursor: pointer;
}
.header-cell > div {
  display: inline-block;
  position: relative;
  padding-right: 22px;
}
.header-cell > div > .hc-sort {
  position: absolute;
  right: 0;
  bottom: 0;
}

.header-cell > div > .hc-sort > img {
  width: 13px;
  height: 20px;
  object-fit: contain;
  display: none;
}

.header-cell > div > .hc-sort > img.hc-sort-asc {
  display: none;
}

.header-cell > div > .hc-sort-normal > .hc-img-normal {
  display: inline;
}

.header-cell > div > .hc-sort-asc > .hc-img-asc {
  display: inline;
}

.header-cell > div > .hc-sort-desc > .hc-img-desc {
  display: inline;
}
