.vedlegg-list li {
  font-size: 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: bold;
  line-height: 1.44;
  color: #004aa1;
  border-bottom: 1px solid #F1F1F1;
  position: relative;
}
.vedlegg-list li:last-child {
  border-bottom: 0;
}
.vedlegg-list img.delete-icon {
  position: absolute;
  top: 6px;
  right: 0;
  cursor: pointer;
}
