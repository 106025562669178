.ps-kontrakt-list li {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.44;
  color: #004aa1;
  border-bottom: 1px solid #F1F1F1;
  position: relative;
  display: block;
  padding: 16px 0 6px 0;
}
.ps-kontrakt-list li:last-child {
  border-bottom: 0;
}

.ps-kontrakt-list li img {
  margin-top: -5px;
}
.ps-kontrakt-list li a {
  cursor: pointer;
  margin-left: 12px;
}
