.arkiv-dokument-title h4 {
    color: #4d4c4d;
    font-size: 1.6rem;
    font-weight: 600;
}

.arkiv-dokument-title  {
    display: flex;
    justify-content: space-between;
}
.arkiv-dokument-title .tags  {
    margin-top: 7px;
}
.arkiv-dokument-files  {
    padding-left: 3rem;
}
.arkiv-dokument-files img {
    height: 14px;
}
