.common-checkbox > label {
  padding-left: 0px;
}
.common-checkbox > label > img {
  vertical-align: middle;
  margin-right: 7px;
  margin-bottom: 4px;
}
.common-checkbox input[type="checkbox"] {
  margin: 0;
  position: absolute;
  visibility: hidden;
}
