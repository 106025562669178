.logg-details-table td {
  padding: 2px 30px 2px 0;
  border-bottom: 1px solid #f1f1f1;
}
.logg-details-table span {
  display: inline-block;
  padding: 0 4px;
}
.logg-details-table td:first-child {
  padding-right: 30px;
}
.logg-details-table .change-type-0 .old-value {
  background-color: #ffdfdd;
}
.logg-details-table .change-type-0 .new-value {
  background-color: #dff5ed;
}
.logg-details-table .change-type-1 .old-value {
  display:none;
}
.logg-details-table .change-type-1 .new-value {
  background-color: #dff5ed;
}

.logg-details-table .change-type-2 .old-value {
  background-color: #ffdfdd;
  text-decoration: line-through;
}
.logg-details-table .change-type-2 .new-value {
  display:none;
}
