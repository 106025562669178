.ordrelinje-table {
    width: 100%;
    margin-bottom: 20px;
}
.ordrelinje-table thead tr th, .ordrelinje-table tbody tr td {
    font-size: 14px;
    padding-top: 4px;
    padding-bottom: 4px;
    text-align: center;
}
.ordrelinje-table thead tr th:first-child, .ordrelinje-table tbody tr td:first-child {
    text-align: left;
}
