.filter > .filter-button {
  border-radius: 0px;
  height: 34px;

  padding: 3px 12px 3px 12px;
  font-size: 16px;
  letter-spacing: 0.3px;
  color: #4a4a4a;
  border-left: solid 1px #e5e5e5;
  border-right: solid 1px #e5e5e5;
  border-top: none;
  border: 1px solid gray;
  border-radius: 5px!important;
  border-color: #e9e9e9;
  margin-right: 20px;
  font-weight: normal;
  background-color: #fff;
}
.filter.btn-group {
  float: none;
}
.filter > .filter-button:hover {
  background-color: #f4f4f4;
}

.filter > .filter-button:active,
.filter > .filter-button.focus,
.filter.open > .filter-button {
  background-color: #f4f4f4 !important;
  box-shadow: none !important;
  border-left: solid 1px #e5e5e5 !important;
  border-right: solid 1px #e5e5e5 !important;
  border-top: 1px solid #e9e9e9 !important;
  border-bottom: solid 1px transparent!important;
}

.filter > .filter-button .caret {
  border-top-width: 7px;
  border-left-width: 7px;
  border-right-width: 7px;
  margin-left: 15px;
}
