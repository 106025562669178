.historic-checkbox {
  position: absolute;
  margin-left: 540px;
  margin-top: 8px;
  z-index: 1000;
}
.historic-checkbox form {
  display: inline-block;
}
.historic-checkbox .spinner {
  margin-left: 8px;
  vertical-align: text-bottom;
}
.page-section-header .inline-spinner {
  vertical-align: text-bottom;
}