.vertical-tabs {
  display: flex;
  align-items: stretch;
  flex-grow: 1;
  overflow: hidden;
}
.vertical-tabs .tabs-column {
  min-width: 20%;
  background: #f3f3f3;
  padding: 0;
  z-index: 10000;
}
.vertical-tabs .content-column {
  flex-grow: 4;
  padding: 0 15px 30px 15px;
}
.vertical-tabs .content-column h3 {
  margin-top: 15px;
}
.vertical-tabs-content {
  display: none;
}
.vertical-tabs-content.visible {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.vertical-tabs-content > div:first-child {
  flex-grow: 2;
}
.vertical-tabs-next-button {
  padding-top: 20px;
}
.vertical-tabs-content:last-child .vertical-tabs-next-button {
  display: none;
}
.vertical-tabs-tab {
  color: rgb(51, 51, 51);
  padding: 15px;
  cursor: pointer;
  display: block;
}
.vertical-tabs-tab.selected {
  background-color: #d8d8d8;
  text-decoration: none;
  color: rgb(51, 51, 51);
}
.vertical-tabs-tab.disabled {
  text-decoration: none;
  cursor: default;
  color: rgb(173, 173, 173);
}
.vertical-tabs-tab:hover {
  background-color: #d8d8d8;
  color: rgb(51, 51, 51);
  text-decoration: none;
}
.vertical-tabs-tab > span.section-number {
  display: inline-block;
  border-radius: 50%;
  border: 1px solid #4d4d4d;
  padding: 2px 8px;
  margin-right: 10px;
}
.vertical-tabs-tab.has-error,
.vertical-tabs-tab.has-error > span.section-number {
  color: #a94442;
  border-color: #a94442;
}
