.bym-tag {
    display: inline-block;
    border-radius: 12px;
    color: #4a4a4a;
    font-size: 12px;
    padding: 0 8px;
    margin: 0 4px;
}
.bym-tag.default {
    border: 1px solid #4d4c4d;
    background-color: #e9e9e9;
}
.bym-tag.green {
    border: 1px solid #22b473;
    background-color: #c9eee1;
}
.bym-tag.blue {
    border: 1px solid #0071bb;
    background-color: #e5f0f7;
}
.bym-tag.orange {
    border: 1px solid #ffaa00;
    background-color: #ffeece;
}
