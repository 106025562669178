
.freetext-filter {
  display: inline-block;
  position: relative;
  margin: 0;
  vertical-align: top;
}
.freetext-filter form {
  padding: 0;
  margin: 0;
}

.freetext-filter .form-group {
  display: inline-block;
  margin: 0;
}


.freetext-filter input {
  display: inline-block;
  background-color: #fff;
  color: #4a4a4a;
  border: 1px solid #e9e9e9;
  border-radius: 5px;
  padding: 0px 52px 0px 12px!important;
  width: 240px;
}
.freetext-filter input:focus {
  border-color: #d8d8d8;
  box-shadow: 0 0 4px 0 rgba(56, 56, 56, 0.1);
  
}
.freetext-filter input::placeholder {
  color: #838383;
}

.freetext-filter .freetext-searchicon {
  background-color: #f3f3f3;
  border: 0;
  border-left: 2px solid #ececec;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 5px 10px 6px 10px;
  position: absolute;
  top: 1px;
  right: 1px;

}
.freetext-filter .freetext-searchicon img {
  height: 18px;
}
