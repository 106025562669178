input.leieobjekt-pris {
  height: 11px !important;
}

div.kontrakt-leieobjekter {
  display: flex;
  justify-content: space-between;
}
.month-input .rdtMonths > table > thead {
  display: none;
}

.sesong-slutt-picker div.rdtPicker {
  margin-left: -97px;
}
label.radio-inline input {
  margin-top: 2.5px !important;
}
.kontrakt-information {
  background-color: #f9f9f9;
  margin: -11px -20px 0 -20px;
  padding: 20px 5px;
  border-top: 1px solid #cccccc;
}
.leieobjekt-information {
  background-color: #f9f9f9;
  margin: 10px -20px 20px -20px;
  padding: 10px 5px;
}
.faktura-information div.radio-buttons {
  padding: 11px 0;
}
.sesong-row .form-group,
.betalingtermin-row .form-group {
  display: flex;
}
.sesong-row .form-group > label,
.betalingtermin-row .form-group > label {
  flex-grow: 1;
  min-width: auto;
  padding-right: 10px;
  align-self: center;
  white-space: nowrap;
}
.sesong-row .form-group > .betalingtermin-row .form-group > div {
  flex-grow: 4;
}
