.kommentar-component {
  padding: 20px 15px;
  font-size: 0.9em;
}
.kommentar-component textarea {
  max-width: 560px;
  height: 140px;
}
.kommentar-component .cancel-button {
  padding-left: 0;
}
.kommentar-empty {
  font-size: 1.2em;
  padding: 15px;
}
.kommentar-component .media-right img {
  cursor: pointer;
}

/* profile image */
.kommentar-component .media-left img {
  width: 24px;
  height: 24px;
}
.kommentar-list {
  margin-top: 20px;
}
.kommentar-item {
  border-top: 1px solid #cccccc;
  margin-top: 20px;
  padding-top: 15px;
}
.kommentar-item h4 {
  margin-top: 5px;
  font-size: 1.05em;
  font-weight: bold;
}
.kommentar-item .media-body > div {
  display: flex;
}
.kommentar-item p {
  margin: 0;
  padding-bottom: 4px;
  white-space: pre-wrap;
  flex-basis: 50%;
  flex-grow: 4;
  display: inline-block;
}
.kommentar-edit-buttons {
  flex-grow: 1;
  flex-basis: 30%;
  align-self: flex-start;
  text-align: center;
}
.kommentar-edit-buttons img {
  width: 14px;
  height: 14px;
  margin-right: 7px;
  cursor: pointer;
}
.kommentar-item span {
  color: #606060;
  font-size: 0.9em;
}