/* ================ BODY ================ */
body {
  font-family: Lato;
  padding-top: 105px;
  background-color: #f1f1f1;
}
html {
  overflow-y: scroll;
}

/* ================ BUTTON ================ */
.btn {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.2;
  color: #4d4d4d;
  border-radius: 6px;
}

.btn:active,
.btn.active,
.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  box-shadow: none;
}

.btn .badge {
  text-shadow: none;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus {
  outline: 0 none;
}

/* .btn-link */
.btn-link {
  color: #0071bb;
  text-decoration: none;
}

.btn-link:hover {
  text-decoration: underline;
}

.btn-link.danger {
  color: #d0021b;
}

/* .btn-bym-standard */
.btn-bym-standard {
  color: #4d4d4d;
  background-color: #f3f3f3;
  border-color: #e9e9e9;
}

.btn-bym-standard:hover,
.btn-bym-standard:focus {
  color: #4d4d4d;
  background-color: #e9e9e9;
  border-color: #d8d8d8;
}

.btn-bym-standard:active,
.btn-bym-standard.active {
  color: #4d4d4d;
  background-color: #d4d4d4;
  border-color: #c4c4c4;
}

.btn-bym-standard.disabled,
.btn-bym-standard[disabled],
fieldset[disabled] .btn-bym-standard,
.btn-bym-standard.disabled:hover,
.btn-bym-standard[disabled]:hover,
fieldset[disabled] .btn-bym-standard:hover,
.btn-bym-standard.disabled:focus,
.btn-bym-standard[disabled]:focus,
fieldset[disabled] .btn-bym-standard:focus,
.btn-bym-standard.disabled.focus,
.btn-bym-standard[disabled].focus,
fieldset[disabled] .btn-bym-standard.focus,
.btn-bym-standard.disabled:active,
.btn-bym-standard[disabled]:active,
fieldset[disabled] .btn-bym-standard:active,
.btn-bym-standard.disabled.active,
.btn-bym-standard[disabled].active,
fieldset[disabled] .btn-bym-standard.active {
  background-color: #e0e0e0;
}

/* .btn-bym-action */
.btn-bym-action {
  color: #4d4d4d;
  background-color: #f2f8fc;
  border-color: #cee4f2;
}

.btn-bym-action:hover,
.btn-bym-action:focus {
  color: #4d4d4d;
  background-color: #e3eff8;
  border-color: #cee4f2;
}

.btn-bym-action:active,
.btn-bym-action.active {
  color: #4d4d4d;
  background-color: #cde3f2;
  border-color: #cee4f2;
}

.btn-bym-action:active:hover,
.btn-bym-action.active:hover,
.open > .dropdown-toggle.btn-bym-action:hover,
.btn-bym-action:active:focus,
.btn-bym-action.active:focus,
.open > .dropdown-toggle.btn-bym-action:focus,
.btn-bym-action:active.focus,
.btn-bym-action.active.focus,
.open > .dropdown-toggle.btn-bym-action.focus {
  color: #4d4d4d;
  background-color: #cde3f2;
  border-color: #cee4f2;
}

.btn-bym-action.disabled,
.btn-bym-action[disabled],
fieldset[disabled] .btn-bym-action,
.btn-bym-action.disabled:hover,
.btn-bym-action[disabled]:hover,
fieldset[disabled] .btn-bym-action:hover,
.btn-bym-action.disabled:focus,
.btn-bym-action[disabled]:focus,
fieldset[disabled] .btn-bym-action:focus,
.btn-bym-action.disabled.focus,
.btn-bym-action[disabled].focus,
fieldset[disabled] .btn-bym-action.focus,
.btn-bym-action.disabled:active,
.btn-bym-action[disabled]:active,
fieldset[disabled] .btn-bym-action:active,
.btn-bym-action.disabled.active,
.btn-bym-action[disabled].active,
fieldset[disabled] .btn-bym-action.active {
  background-color: #f2f8fc;
  border-color: #cee4f2;
}

/* .btn-bym-positive */
.btn-bym-positive {
  color: #4d4d4d;
  background-color: #daf6ed;
  border-color: #b7ecdc;
}

.btn-bym-positive:hover,
.btn-bym-positive:focus {
  color: #4d4d4d;
  background-color: #c2efe1;
  border-color: #c2efe1;
}

.btn-bym-positive:active:focus,
.btn-bym-positive.active:focus {
  color: #4d4d4d;
  background-color: #90e2c9;
  border-color: #71d9b8;
}

.btn-bym-positive.disabled,
.btn-bym-positive[disabled],
fieldset[disabled] .btn-bym-positive,
.btn-bym-positive.disabled:hover,
.btn-bym-positive[disabled]:hover,
fieldset[disabled] .btn-bym-positive:hover,
.btn-bym-positive.disabled:focus,
.btn-bym-positive[disabled]:focus,
fieldset[disabled] .btn-bym-positive:focus,
.btn-bym-positive.disabled.focus,
.btn-bym-positive[disabled].focus,
fieldset[disabled] .btn-bym-positive.focus,
.btn-bym-positive.disabled:active,
.btn-bym-positive[disabled]:active,
fieldset[disabled] .btn-bym-positive:active,
.btn-bym-positive.disabled.active,
.btn-bym-positive[disabled].active,
fieldset[disabled] .btn-bym-positive.active {
  background-color: #daf6ed;
}

.btn-bym-negative-link,
.btn-bym-negative-link:active,
.btn-bym-negative-link:visited {
  color: #d0021b;
  background-color: transparent;
  border: 0;
}
.btn-bym-negative-link:hover {
  color: #d0021b;
  background-color: transparent;
  border: 0;
  text-decoration: underline;
}

/* .btn-bym-negative */
.btn-bym-negative {
  color: #4d4d4d;
  background-color: #fee0dc;
  border-color: #fdc2bd;
}

.btn-bym-negative:hover {
  color: #4d4d4d;
  background-color: #fdc0ba;
  border-color: #fca79e;
}

.btn-bym-negative:focus {
  color: #4d4d4d;
  background-color: #fdc0ba;
  border-color: #fca79e;
}

.btn-bym-negative:active,
.btn-bym-negative.active {
  color: #4d4d4d;
  background-color: #fdc0ba;
  border-color: #fca79e;
}

.btn-bym-negative:active:focus,
.btn-bym-negative.active:focus {
  color: #4d4d4d;
  background-color: #fca198;
  border-color: #fc8479;
}

.btn-bym-negative.disabled,
.btn-bym-negative[disabled],
fieldset[disabled] .btn-bym-negative,
.btn-bym-negative.disabled:hover,
.btn-bym-negative[disabled]:hover,
fieldset[disabled] .btn-bym-negative:hover,
.btn-bym-negative.disabled:focus,
.btn-bym-negative[disabled]:focus,
fieldset[disabled] .btn-bym-negative:focus,
.btn-bym-negative.disabled.focus,
.btn-bym-negative[disabled].focus,
fieldset[disabled] .btn-bym-negative.focus,
.btn-bym-negative.disabled:active,
.btn-bym-negative[disabled]:active,
fieldset[disabled] .btn-bym-negative:active,
.btn-bym-negative.disabled.active,
.btn-bym-negative[disabled].active,
fieldset[disabled] .btn-bym-negative.active {
  background-color: #fee0dc;
  border-color: #fdc2bd;
}

.form-control {
  border: 1px solid #e5e5e5;
  box-shadow: inset 0 0 0 rgba(0, 0, 0, .075);
  border-radius: 7px;
}
.form-control:focus {
	border-color: #cee4f2;
	outline: 0;
	box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(206, 228, 242, 0.6);
}
.tab-content {
  padding: 0;
}