
.add-kontrakt-special-dialog .modal-header {
  background-color: #f1f1f1;
}
.add-kontrakt-special-dialog .modal-body {
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  overflow-x: hidden;
}
.add-kontrakt-special-dialog .page-section-header h1 {
  font-size: 1em;
}
.add-kontrakt-special-dialog .page-section-header img {
  height: 18px;
  margin-top: -4px;
  margin-right: 10px;
}
.add-kontrakt-special-dialog .vedlegg-list li {
  margin: 4px 0 0 0;

}
form#kontrakt-special-form button {
  float: right;
  margin-bottom: 20px;
}