// @use "styles";
// @import "../../../styles/index.scss";

.leaflet-container {
    height: 500px;
    width: 100%;
}
.bym-map-container {
  position: relative;
}
.bym-map-big {
    height: 700px;
}
.bym-marker-popup h4 {
    color: #0078A8;
}
.bym-marker-popup h4 a {
    text-decoration: underline;
}
.bym-marker-popup table {
    width: 100%;
}

.bym-marker-popup table td:first-child {
    font-weight: bolder;
    padding: 2px 8px 2px 0;
    vertical-align: top;
}
.bym-marker-popup table td:last-child {
    text-align: right;
}
.bym-marker-tooltip h5 {
  margin-bottom: 0;
  padding: 2px 0px;
  font-size: 1.4rem;
  border-bottom: 1px solid #2A2859;
  margin-bottom: 10px;
}
.bym-marker-tooltip table  {
    margin-top: 4px;
    width: 100%;
}
.bym-marker-tooltip table td {
  vertical-align: baseline;
}
.bym-marker-tooltip-label {
    font-weight: bold;
    padding-right: 8px;
}
.bym-map-area {
  z-index: 100000;
  position: absolute;
  background-color: rgba(199,246,201,0.5);
  text-shadow: #fff;
  border: 1px solid #034b45;
  padding: 4px 10px;
  top: 12px;
  left: 20%;
  right: 20%;
}
.leaflet-draw-draw-polygon {
  background-position: 3px 5px!important;
  background-size: auto!important;
  background-color: rgb(42, 40, 89)!important;
  border-radius: 0!important;
  background-image: url("data:image/svg+xml,<svg height='20' viewBox='0 0 35 29' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M23.5 1.5L2.5 6.5L18 25.5L32 19L23.5 1.5Z' stroke='rgb(255, 255, 255)' stroke-width='2' /><circle cx='23.2812' cy='2.5' r='2' fill='white' stroke='rgb(42, 40, 89)' /><circle cx='32.0312' cy='19.7939' r='2' fill='white' stroke='rgb(42, 40, 89)' /><circle cx='17.8125' cy='26.3828' r='2' fill='white' stroke='rgb(42, 40, 89)' /><circle cx='2.5' cy='7.44141' r='2' fill='white' stroke='rgb(42, 40, 89)' /></svg>")!important;
}
.leaflet-bar {
  border-radius: 0!important;
}

// Zoom buttons
.leaflet-bar {
  border: 0!important;
  a.leaflet-control-zoom-in,
  a.leaflet-control-zoom-out {
    background-color: #2a2859;
    color: #fff;
    height: 52px !important;
    width: 60px !important;
    align-items: center;
    justify-content: center;
    display: flex;
    border: 0px;
    margin-top: 10px;
    border-radius: 5px!important;
    box-shadow: 3px 4px 4px -4px rgba(0,0,0,0.5);
    &:hover {
      color: #fff;
      background: #1f42aa;
      border-color: #1f42aa;
      align-items: center;
      justify-content: center;
      display: flex;
    }
    &.leaflet-disabled {
      background-color: #f2f2f2;
      border: 0px;
      cursor: not-allowed;
      color: #666666;
    }
  }
}
