
.leieobjekt-dialog  {
  width: 90%;
  max-width: 900px;
}
.leieobjekt-dialog .modal-header {
  background-color: #f1f1f1;
}
.leieobjekt-dialog .modal-body {
  max-height: calc(100vh - 120px);
  overflow-y: auto;
  overflow-x: hidden;
}
