// @import "../../../styles/index.scss";

.bym-map-layer-controll {
    position: absolute;
    right: 10px;
    z-index: 1003;
    top: 10px;

    button {
        padding:0px;
        margin:0px;
        border:2px solid #2a2859;
        box-shadow: 3px 4px 4px -4px rgba(0,0,0,0.5);

        img {
            width: 90px;
            height: 90px;
        }

        svg {
            position: absolute;
            left: 9px;
            bottom: 7px;
        }

        &:hover {
            border: 2px solid #1f42aa;
        }
    }
}