.eiendom-list-row a {
  color: #0173b6;
}

.eiendom-list-row .elr-fakturastatus-red {
  color: #d0021b !important;
}

.eiendom-list-row .elr-fakturastatus-green {
  color: #487514 !important;
}
.eiendom-list-row th {
  white-space: nowrap;
}

.eiendom-list-table th:first-child {
  width: 25%;
}
.eiendom-list-table th:nth-child(2) {
  width: 25%;
}
