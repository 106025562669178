.timeleie-table {
  /* border: 1px solid #bbbbbb; */
  border-bottom: 1px solid #bbb;
  margin-bottom: 10px;
}
.timeleie-table > thead > tr > th {
  border-bottom: 1px solid #bbb;
  padding: 2px 10px;
  border: 0;
}
.timeleie-table > tbody > tr > td {
  border-top: 1px solid #bbb;
  padding: 8px 10px;
  vertical-align: top;
}
.timeleie-table button {
  padding: 0 12px;
}

.timeleie-table td input {
  max-width: 120px;
}
.timeleie-table td .form-group {
  margin-top: 0;
}
.timeleie-table  .common-checkbox {
  margin-top: 6px;
}
