.logg-component {
  padding: 20px 15px;
  font-size: 0.9em;
}
.logg-component table > tbody > tr > td {
  padding: 12px 18px;
}
.logg-component td button {
  padding: 0;
}
.logg-details {
  display: flex;
  align-items: center;
}
.logg-details > span:not(:only-child) {
  margin-left: 10px;
}
