.bilde-container {
  margin-right: 20px;
  text-align: center;
  padding: 0;
}
.bilde-container .bilde {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  height: 137px;
  cursor: pointer;
}
.bilde-container .bilde img {
  max-height: 137px;
  min-height: 137px;
  max-width: 100%;
}
.bilde-container .bilde-label {
  position: relative;
  padding: 4px 26px 0 4px;
  text-align: left;
}
.bilde-container .bilde-label label{
  position: relative;
}
.bilde-container .bilde-label img {
  position: absolute;
  right: 0;
  top: 4px;
  cursor: pointer;
}
