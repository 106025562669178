.kontraktsavtale-dialog.modal-lg {
  min-width: 80%;
}

.kontraktsavtale-dialog .modal-header h4 {
  font-size: 3rem;
}
.kontraktsavtale-dialog .modal-header h5 {
  font-size: 2rem;
  float: left;
  padding-top: 10px;
}
.kontraktsavtale-dialog .form-group {
  margin-top: 15px;
}
.leieobjekt-picker {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  align-items: stretch;
  padding: 10px;
}
.leieobjekt-picker-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 33%;
  max-width: 33%;
  margin-bottom: 10px;
  padding-right: 15px;
}
.leieobjekt-picker-item:nth-child(3n + 3) {
  padding-right: 0;
}

@media (max-width: 992px) {
  .leieobjekt-picker-item {
    flex-basis: 49%;
    max-width: 49%;
  }

  .leieobjekt-picker-item:nth-child(3n + 3) {
    padding-right: 15px;
  }
  .leieobjekt-picker-item:nth-child(2n + 2) {
    padding-right: 0;
  }

}
.leieobjekt-picker-icon {
  padding: 15px 0;
  border: 1px solid #e5e5e5;
  border-radius: 5px 5px 0 0;
  background-color: #f8fbfd;
  display: flex;
  justify-content: center;
}
.leieobjekt-picker-content {
  padding: 10px;
  border: 1px solid #e5e5e5;
  border-top: 0;
  flex-grow: 1;
  margin: 0 0 10px 0;
  border-radius: 0 0 5px 5px;
}
.leieobjekt-picker-item:hover .leieobjekt-picker-content,
.leieobjekt-picker-item:hover .leieobjekt-picker-icon,
.leieobjekt-picker-item[data-checked="true"] .leieobjekt-picker-content,
.leieobjekt-picker-item[data-checked="true"] .leieobjekt-picker-icon {
  border-color: #bbbbbb;
}
.leieobjekt-bruk {
  background-color: #f8fbfd;
  margin: 20px -15px 0 -15px;
  padding: 10px 15px 25px 15px;
}
.leieobjekt-bruk-text {
  display: flex;
}
.leieobjekt-bruk-text p {
  padding: 22px 10px 0 0;
}
.leieobjekt-bruk-text .form-group {
  flex-grow: 1;
}

.leieobjekt-picker > div .form-group label {
  padding: 0;
  font-weight: bold;
  font-size: 1.6rem;
  color: #0071bb;
}
.leieobjekt-picker-item[data-checked="true"] .has-leieobjekt-error {
  color: #a94442;
}
.kontraktsavtale-dialog ul.vedlegg-liste {
  list-style-type: none;
  padding: 0;
}
.kontraktsavtale-dialog .vedlegg-liste li {
  display: flex;
  align-items: center;
  width: 457px;
  margin-bottom: 4px;
  min-height: 30px;
  /* padding: 8px 0; */
}
.kontraktsavtale-dialog .vedlegg-liste li div {
  display: flex;
  align-items: center;
  flex-grow: 2;
}
.kontraktsavtale-dialog .vedlegg-liste li svg {
  margin-right: 10px;
  padding: 0;
}
.kontraktsavtale-dialog .vedlegg-liste li button {
  padding: 0;
}
