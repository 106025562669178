.generellInfo {
  .glyphicon {
    margin-right: 0.2em;
    font-weight: 600;
  }
  margin-top: 2em;
  margin-bottom: 2em;
  font-weight: 500;
}

.beregnetFakturaBelop .detaljer {
  margin-top: 1em;
  .detaljelinje {
    display: flex;
    align-items: center;
  }
  .forklaring {
    margin-left: 1em;
    display: flex;
    align-items: center;
    font-style: italic;

    .glyphicon {
      margin-right: 0.2em;
      font-weight: 600;
    }
  }
}
